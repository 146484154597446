.App {
  text-align: center;
}

.SideMenu {
  position: fixed;
  height: 100vh;
  width: 230px;
  float: left;
  background-color: lightslategrey;
}

.AppContent {
  margin-left: 230px;
  height: 100vh;
}

.Filters>div {
  padding: 10px;
}

.AppContent {
  background-color: lightsteelblue;
}

.SearchBar {
  width: calc(100vw - 300px);
  margin-left: 20px;
}

.SearchBar>input {
  width: 100%;
}

.GameGrid {
  display: grid;
  grid-gap: 1rem;
}

@media (min-width: 600px) {
  .GameGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .GameGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.GridElement {
  margin: 20px;
}

.GameThumbnail {
  width: 250px;
  height: 121px;
}

.GameThumbnail>img {
  width: 100%;
  height: 100%;
}

.GameInfo {
  height: 30px;
  width: 250px;
  background-color: white;
}